<h1 mat-dialog-title>
  Escolha uma Empresa
</h1>

<div mat-dialog-content>

  <mat-form-field class="w-100" appearance="outline">
    <mat-label>
      Empresa
    </mat-label>
    <mat-select placeholder="Empresas" [(ngModel)]="selectedCompany">
      <mat-option *ngFor="let company of companies" [value]="company.id">
        {{company.companyName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>

<div mat-dialog-actions>

  <button mat-raised-button [disabled]="!selectedCompany" color="primary" class="w-100" (click)="select()">
    Selecionar
  </button>

  <button mat-button color="accent" class="w-100 text-center btn-cancel" (click)="cancel()">
    Cancelar
  </button>

</div>
