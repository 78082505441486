import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserSessionService } from 'src/app/services/auth/user-session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {

  public isHomolog = environment.homolog;
  public companyName: string;
  public userName: string;
  public companyTypeDescription: string;

  constructor(
    private userSessionService: UserSessionService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loadUserData()
  }

  loadUserData(): void {
    const userSession = this.userSessionService.session;
    const selectedCompanyId = this.userSessionService.selectedCompanyId;

    if (userSession && selectedCompanyId) {
      const company = userSession.companies.find(c => c.id == selectedCompanyId);

      if (company) {
        this.companyName = company.companyName ?? '';
        this.userName = userSession.fullName;
        this.companyTypeDescription = this.userSessionService.getCompanyTypeDescription(userSession.companyType);
      }
      else {
        this.logout();
      }
    }
    else {
      this.logout();
    }
  }

  logout(): void {
    this.authService.logout();
  }

}
