<button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
    <mat-icon>
        account_circle
    </mat-icon>
</button>
<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <mat-card fxLayout="row" fxLayoutAlign="space-around center" class="user-info p-0">
        <div fxLayout="column" fxLayoutAlign="center center">
            <h2>
                {{userName}}
            </h2>
            <h3 class="secondary-text-color">
                {{companyTypeDescription}}
            </h3>
            <p class="secondary-text-color">
                Industria: {{companyName}}
            </p>
        </div>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-divider></mat-divider>
    <a mat-menu-item (click)="logout()">
        <mat-icon>
            power_settings_new
        </mat-icon>
        <span>
            Sair
        </span>
    </a>
</mat-menu>
