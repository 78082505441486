import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'core-load-modal',
  templateUrl: './load-modal.component.html',
  styleUrls: ['./load-modal.component.scss']
})
export class LoadModalComponent implements OnInit {

  @Input() showModal: boolean;
  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
