import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {path: 'changepassword', loadChildren: () => import('./pages/change_password/change.password.module').then(m => m.ChangePasswordModule)},
  {path: 'forgotpassword', loadChildren: () => import('./pages/forgot_password/forgot.password.module').then(m => m.ForgotPasswordModule)},
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
