<div fxLayout="row" class="breadcrumb p-2">
    <a routerLink="/" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start" (click)="closeSubMenus()">
        <mat-icon>home</mat-icon>&nbsp;&nbsp;&nbsp;
        <span class="breadcrumb-title">Página Principal</span>
    </a>
    <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start">
        <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>

    </div>
</div>
