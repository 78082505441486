import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { CompanyModel } from 'src/app/models/company/company.model';
import { PermissionModel } from 'src/app/models/systemuser/permission.model';
import { UserCompanyModel } from 'src/app/models/user-company/user-company.model';
import { UserSessionService } from 'src/app/services/auth/user-session.service';
import { CompanyApplicationService } from 'src/app/services/company/company-application.service';

interface DialogData {
  companies: CompanyModel[]
  returnApp: string
  redirect: string
}

@Component({
  selector: 'app-choose-company',
  templateUrl: 'choose-company.component.html',
  styleUrls: ['./choose-company.component.scss']
})
export class ChooseCompanyComponent {

  public companies: CompanyModel[] = [];
  public selectedCompany: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private _dialogRef: MatDialogRef<ChooseCompanyComponent>,
    private _userSessionService: UserSessionService,
    private _companyApplicationsService: CompanyApplicationService,
    private _actRouter: ActivatedRoute,
    private _router: Router
  ) {
    this.companies = data.companies;
  }

  public cancel(): void {
    const token = this._userSessionService.token;

    if (token) {
      this._userSessionService.inactiveSession(token).subscribe({
        next: () => {
          this._userSessionService.reset();
          this._dialogRef.close(false);
        },

        error: () => {
          this._userSessionService.reset();
          this._dialogRef.close(false);
        }
      });
    }
  }

  public select(): void {
    const selectedCompanyId = this.selectedCompany;
    const token = this._userSessionService.token;

    if (token) {
      this._userSessionService.selectCompany(selectedCompanyId, token).subscribe({
        next: (newToken) => {
          this._userSessionService.setSelectedCompanyId(selectedCompanyId);

          const session = this._userSessionService.session;
          const perm = this._userSessionService.permissions;

          const permFiltered = perm?.filter(option => option.companyId == selectedCompanyId)!;

          this._userSessionService.setPermissions(permFiltered);


          this._companyApplicationsService.getByCompanyId(selectedCompanyId).subscribe({
            next: (apps) => {
              const token = this._userSessionService.token;
              this._userSessionService.setApplications(apps);

              if (apps.length == 1 && token) {
                const url = apps[0].url;
                const a = document.createElement('a');
                a.href = `${url}/authorize?accessToken=${newToken}&redirect=${this.data.redirect || ''}`;
                a.target = '_blank';
                a.click();
              }
              else {
                if (this.data.returnApp) {
                  const url = apps.find(x => x.name == this.data.returnApp)?.url;
                  if (url) {
                    window.location.href = `${url}/authorize?accessToken=${newToken}&redirect=${this.data.redirect || ''}`;
                    return;
                  }
                }
                const a = document.createElement('a');
                a.href = '/';
                a.target = '_blank';
                a.click();
              }
              this._dialogRef.close(true);

            },

            error: () => {
              console.error('erro ao selecionar projetos, tente novamente');
              this._dialogRef.close(true);
            }
          });

        },

        error: () => {
          console.error('Erro ao selecionar empresa');
        }
      })
    }
    else {
      this.cancel();
    }

  }

}
