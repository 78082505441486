import { MenuModel } from "src/app/models/common/menu.model";

export const MenuData: MenuModel[] = [
    {
        id: 1,
        pageId: 0,
        title: 'Pagina Principal',
        routerLink: '/',
        href: null,
        icon: 'home',
        target: null,
        hasSubMenu: false,
        parentId: 0
    },
    {
        id: 2,
        pageId: 0,
        title: 'Usuários',
        routerLink: null,
        href: null,
        icon: 'perm_identity',
        target: null,
        hasSubMenu: true,
        parentId: 0
    },
    {
        id: 3,
        pageId: 1,
        title: 'Usuários',
        routerLink: '/system-user/users',
        href: null,
        icon: '',
        target: null,
        hasSubMenu: false,
        parentId: 2
    },
    {
        id: 4,
        pageId: 2,
        title: 'Grupo de Usuários',
        routerLink: '/system-user/user-groups',
        href: null,
        icon: '',
        target: null,
        hasSubMenu: false,
        parentId: 2
    },
    {
        id: 5,
        pageId: 3,
        title: 'Permissões de Segurança',
        routerLink: '/system-user/permissions',
        href: null,
        icon: '',
        target: null,
        hasSubMenu: false,
        parentId: 2
    },
    {
        id: 6,
        pageId: 4,
        title: 'Configurações de senha',
        routerLink: '/system-user/password-settings',
        href: null,
        icon: '',
        target: null,
        hasSubMenu: false,
        parentId: 2
    },
    // {
    //     id: 7,
    //     pageId: 4,
    //     title: 'Configurações do sistema',
    //     routerLink: '/systemuser/systemsettings',
    //     href: null,
    //     icon: '',
    //     target: null,
    //     hasSubMenu: false,
    //     parentId: 2
    // },
    {
        id: 8,
        pageId: 0,
        title: 'Empresa',
        routerLink: null,
        href: null,
        icon: 'assessment',
        target: null,
        hasSubMenu: true,
        parentId: 0
    },
    {
        id: 9,
        pageId: 5,
        title: 'Empresa',
        routerLink: '/companies',
        href: null,
        icon: '',
        target: null,
        hasSubMenu: false,
        parentId: 8
    },
]