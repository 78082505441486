import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationModel } from 'src/app/models/systemuser/application.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  private _url = environment.api;

  constructor(
    private _http: HttpClient
  ) { }

  getByCompany(companyId: number): Observable<ApplicationModel[]> {
    return this._http.get<ApplicationModel[]>(`${this._url}/applications/getByCompany/${companyId}`);
  }
}
