import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettingsModel } from 'src/app/models/common/app-settings.model';
import { MenuService } from 'src/app/services/common/menu.service';
import { SettingsService } from 'src/app/services/common/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit {
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation: true
  };

  public menuItems: Array<any>;
  public settings: AppSettingsModel = new AppSettingsModel();
  public isHomolog = environment.homolog;

  constructor(
    private menuService: MenuService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.menuItems = this.menuService.getMenu();

    this.settingsService.onChangeSettings().subscribe({
      next: (settings) => {
        this.settings = settings;
      }
    })
  }

  ngDoCheck() {
    if (this.settings.fixedSidenav) {
      if (this.psConfig.wheelPropagation == true) {
        this.psConfig.wheelPropagation = false;
      }
    }
    else if (this.psConfig.wheelPropagation == false) {
      this.psConfig.wheelPropagation = true;
    }
  }

  public closeSubMenus() {
    let menu = document.getElementById("vertical-menu");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

}