<div class="app-project-menu">
  <!-- Shortcuts toggle -->
  <button
    mat-icon-button
    (click)="openPanel()"
    matTooltip="Aplicações"
    #shortcutsOrigin>
    <mat-icon>
      grid_view
    </mat-icon>
  </button>

  <!-- Shortcuts panel -->
  <ng-template #shortcutsPanel>
    <div class="panel-container">
          <!-- Shortcuts -->
        <div class="grid grid-cols-2 grid-flow-row">
          <!-- Shortcut -->
          <ng-container *ngFor="let shortcut of shortcuts; trackBy: trackByFn">
            <div class="panel-body">
              <a
                class="flex flex-col items-center justify-center wh-full h-full py-3 no-underline"
                (click)="goToApplication(shortcut)">
                <div class="icon-container relative flex shrink-0 items-center justify-center mb-2 rounded-full" [ngStyle]="{'background-color': shortcut.background}">
                  <mat-icon
                    class="z-10"
                  >{{shortcut.icon}}</mat-icon>
                </div>
                <div class="font-medium text-center">{{shortcut.label}}</div>
                <div class="text-md text-center text-secondary">{{shortcut.description}}</div>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- No shortcuts -->
        <ng-container *ngIf="!shortcuts || !shortcuts.length">
          <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
            <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
              <mat-icon
                class="text-primary-700 dark:text-primary-50"
                [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>
            </div>
            <div class="mt-5 text-2xl font-semibold tracking-tight">Sem acessos disponíveis</div>
            <div class="wh-full max-w-60 mt-1 text-md text-center text-secondary">Quando você tiver algum acesso, eles aparecerão aqui.</div>
          </div>
        </ng-container>
    </div>
  </ng-template>

</div>

<!--<div class="project-menu-container">-->
<!--    <div class="menu" [class.active]="active" (click)="active = !active">-->
<!--      <div class="btn trigger">-->
<!--        <span class="bar"></span>-->
<!--        <span class="bar"></span>-->
<!--        <span class="bar"></span>-->
<!--      </div>-->
<!--      <div class="icons">-->
<!--        <div class="rotater" *ngFor="let app of applications">-->
<!--          <div class="btn btn-icon" [style]="'background-color:' + app.background"-->
<!--            (click)="goToApplication(app)" [title]="'Acessar sistema de ' + app.name">-->
<!--            <span class="material-icons">{{ app.icon }}</span>-->
<!--            &lt;!&ndash; [ngStyle]="{'color': app.background}" &ndash;&gt;-->
<!--            <span class="title-project" style="color: #333">{{ app.name }}</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!-- -->
