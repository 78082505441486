import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { CompanyApplicationModel } from 'src/app/models/company/company-application.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyApplicationService {

  private _url = environment.api;

  constructor(
    private _http: HttpClient
  ) { }

  public getByCompanyId(companyId: number): Observable<CompanyApplicationModel[]> {
    return this._http.get<CompanyApplicationModel[]>(`${this._url}/applications/getByCompany/${companyId}`);
  }

  async GetApplicationByCompany(companyId: number): Promise<CompanyApplicationModel[]> {
    return lastValueFrom(this._http.get<CompanyApplicationModel[]>(`${this._url}/applications/getByCompany/${companyId}`));
  }
}
