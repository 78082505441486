import { Component } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { AppSettingsModel } from 'src/app/models/common/app-settings.model';
import { MenuService } from 'src/app/services/common/menu.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

    public breadcrumbs: {
        name: string;
        url: string
    }[] = [];

    public pageTitle: string;

    public settings: AppSettingsModel = new AppSettingsModel();

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _title: Title,
        private _menuService: MenuService
    ) {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.breadcrumbs = [];
                this.parseRoute(this._router.routerState.snapshot.root);
                this.pageTitle = "";
                this.breadcrumbs.forEach(breadcrumb => {
                    this.pageTitle += ' > ' + breadcrumb.name;
                })
                this._title.setTitle(this.settings.name + this.pageTitle);
            }
        })
    }

    private parseRoute(node: ActivatedRouteSnapshot): void {
        if (node.data['breadcrumb']) {

            if (node.url.length) {
                for (const x in node.data['breadcrumb']) {
                    const breadcrumbName = node.data['breadcrumb'][x];
                    let urlSegments: UrlSegment[] = [];
                    node.pathFromRoot.forEach(routerState => {
                        urlSegments = urlSegments.concat(routerState.url);
                    });
                    let url = urlSegments.map(urlSegment => {
                        return urlSegment.path;
                    }).join('/');
                    this.breadcrumbs.push({
                        // name: node.data['breadcrumb'],
                        name: breadcrumbName,
                        url: '/' + url
                    });
                }
            }
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }

    public closeSubMenus(): void {
        if (this.settings.menu == 'vertical') {
            this._menuService.closeAllSubMenus();
        }
    }


}
