import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { TokenResponseModel } from 'src/app/models/auth/token-response.model';
import { UserSessionModel } from 'src/app/models/auth/user-session.model';
import { CompanyApplicationModel } from 'src/app/models/company/company-application.model';
import { PermissionModel } from 'src/app/models/systemuser/permission.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  private _url = environment.api;

  private _session: UserSessionModel | null;
  private _token: string | null;
  private _selectedCompanyId: number | null;
  private _permissions: PermissionModel[] | null;
  private _applications: CompanyApplicationModel[] | null;

  constructor(
    private _http: HttpClient
  ) { }

  public get session(): UserSessionModel | null {
    if (this._session) {
      return this._session;
    }
    else {
      const sessionString = localStorage.getItem('Specialty.SSO.Session');

      if (sessionString) {
        this._session = JSON.parse(sessionString);
        return this._session;
      }
      else {
        // throw Error('Sessão não encontrada');
        return null;
      }
    }
  }

  public get token(): string | null {
    if (this._token) {
      return this._token;
    }
    else {
      const tokenString = localStorage.getItem('Specialty.SSO.Token');

      if (tokenString) {
        this._token = tokenString;
        return this._token;
      }
      else {
        // throw Error('Token não encontrado');
        return null;
      }
    }
  }

  public get selectedCompanyId(): number | null {
    if (this._selectedCompanyId) {
      return this._selectedCompanyId;
    }
    else {
      const selectedCompanyIdString = localStorage.getItem('Specialty.SSO.SelectedCompanyId');

      if (selectedCompanyIdString) {
        this._selectedCompanyId = +selectedCompanyIdString;
        return this._selectedCompanyId;
      }
      else {
        // throw Error('Empresa não encontrada');
        return null;
      }
    }
  }

  public get permissions(): PermissionModel[] | null {
    if (this._permissions) {
      return this._permissions;
    }
    else {
      const permissionsString = localStorage.getItem('Specialty.SSO.Permissions');

      if (permissionsString) {
        this._permissions = JSON.parse(permissionsString);
        return this._permissions;
      }
      else {
        // throw Error('Permissões não encontradas');
        return null;
      }
    }
  }

  public get applications(): CompanyApplicationModel[] | null {
    if (this._applications) {
      return this._applications;
    }
    else {
      const applicationsString = localStorage.getItem('Specialty.SSO.Applications');

      if (applicationsString) {
        this._applications = JSON.parse(applicationsString);
        return this._applications;
      }
      else {
        // throw Error('Aplicações não encontradas');
        return null;
      }
    }
  }

  public setSession(session: UserSessionModel): void {
    this._session = session;
    localStorage.setItem('Specialty.SSO.Session', JSON.stringify(session));
  }

  public setToken(token: string): void {
    this._token = token;
    localStorage.setItem('Specialty.SSO.Token', token);
  }

  public setSelectedCompanyId(selectedCompanyId: number): void {
    this._selectedCompanyId = selectedCompanyId;
    localStorage.setItem('Specialty.SSO.SelectedCompanyId', selectedCompanyId.toString());
  }

  public setPermissions(permissions: PermissionModel[]): void {
    this._permissions = permissions;
    localStorage.setItem('Specialty.SSO.Permissions', JSON.stringify(permissions));
  }

  public setApplications(applications: CompanyApplicationModel[]): void {
    this._applications = applications;
    localStorage.setItem('Specialty.SSO.Applications', JSON.stringify(applications));
  }

  public inactiveSession(token: string): Observable<void> {
    const request = {
      token: token
    };

    return this._http.post<void>(`${this._url}/userSession/inactive`, request);
  }

  public reset(): void {
    localStorage.removeItem('Specialty.SSO.Session');
    this._session = null;

    localStorage.removeItem('Specialty.SSO.SelectedCompanyId');
    this._selectedCompanyId = null;

    localStorage.removeItem('Specialty.SSO.Token');
    this._token = null;

    localStorage.removeItem('Specialty.SSO.Permissions');
    this._permissions = null;

    localStorage.removeItem('Specialty.SSO.Applications');
    this._applications = null;
  }

  public selectCompany(selectedCompanyId: number, token: string): Observable<string> {
    const request = {
      selectedCompanyId: selectedCompanyId,
      token: token
    };

    return this._http.post<TokenResponseModel>(`${this._url}/userSession/select-company`, request)
      .pipe(
        map((response: TokenResponseModel) => {
          if (response.authenticated) {
            this.setToken(response.accessToken);
            return response.accessToken;
          } else {
            return '';
          }
        })
      );
  }

  public getCompanyTypeDescription(companyType: string): string {
    switch (companyType) {
      case 'SP':
        return 'Specialty';
      case 'DI':
        return 'Distribuidor';
      default:
        return 'Empresa';
    }
  }
}
