import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppSettingsModel } from 'src/app/models/common/app-settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  subjectSettings: Subject<AppSettingsModel> = new Subject<AppSettingsModel>();

  constructor() { }

  onChangeSettings(): Observable<AppSettingsModel> {
    return this.subjectSettings.asObservable();
  }

  changeSettings(appSettingsModel: AppSettingsModel) {
    this.subjectSettings.next(appSettingsModel);
  }
}
