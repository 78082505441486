import { ChangeDetectorRef, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { UserSessionService } from '../services/auth/user-session.service';
import { BehaviorService } from '../services/common/behavior.service';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private _userSessionService: UserSessionService,
    private _behaviorService: BehaviorService,
    private _authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    setTimeout(() => {
      this._behaviorService.messagewaitAMoment.next('Aguarde um momento, por favor');
      this._behaviorService.waitAMoment.next(true);
    });

    const token = this._userSessionService.token;

    if (request.url.includes('auth')) {
      return next.handle(request)
        .pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              setTimeout(() => {
                this._behaviorService.waitAMoment.next(false);
              }, 500);
            }

            return event;
          })
        );
    }
    else {
      const newRequest = request.clone({
        headers: request.headers.set('Authorization', token ? `Bearer ${token}` : '')
      });

      return next.handle(newRequest)
        .pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              setTimeout(() => {
                this._behaviorService.waitAMoment.next(false);
              }, 500);
            }

            return event;
          }),
          catchError(error => {
            this._behaviorService.waitAMoment.next(false);

            if (error instanceof HttpErrorResponse) {
              if (error.status == 401) {
                this._authService.logout();
              }
            }

            return throwError(() => error);
          })
        );
    }
  }
}
