import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadModalComponent } from './components/load-modal/load-modal.component';
import { ChooseCompanyComponent } from './dialogs/choose-company.component';
import { MaterialModule } from '../@material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullScreenComponent } from './components/fullscreen/fullscreen.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { MenuComponent } from './components/menu/menu.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { MaskHourDirective } from './directives/mask-hour.directive';
import { MaskCPFDirective } from './directives/mask-cpf.directive';
import { ChipsComponent } from './components/chips/chips.component';
import { ProjectMenuComponent } from './components/project-menu/project-menu.component';

@NgModule({
  declarations: [
    LoadModalComponent,
    ChooseCompanyComponent,
    FullScreenComponent,
    LoadModalComponent,
    UserMenuComponent,
    MenuComponent,
    SidenavComponent,
    ContentHeaderComponent,
    BreadcrumbComponent,
    MaskCPFDirective,
    MaskHourDirective,
    ChipsComponent,
    ProjectMenuComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    RouterModule,
  ],
  exports: [
    LoadModalComponent,
    ChooseCompanyComponent,
    FullScreenComponent,
    LoadModalComponent,
    UserMenuComponent,
    MenuComponent,
    SidenavComponent,
    ContentHeaderComponent,
    BreadcrumbComponent,
    ChipsComponent,
    MaskCPFDirective,
    MaskHourDirective,
    ProjectMenuComponent
  ]
})
export class CoreModule { }
