import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

import { MenuData } from 'src/app/data/common/menu.data';
import { MenuModel } from 'src/app/models/common/menu.model';
import { UserSessionService } from '../auth/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private toggleSidenavSubject: Subject<void>;

  constructor(
    private _location: Location,
    private _userSessionService: UserSessionService
  ) {
  }

  public getMenu(): MenuModel[] {
    try {
      let responseMenu: MenuModel[] = [];

      MenuData.forEach(menu => {
        if (menu.parentId != 0) {
          const menuOnPermission = this._userSessionService.permissions?.filter(p => p.pageId == menu.pageId);

          if (menuOnPermission != null && menuOnPermission.length == 1 && menuOnPermission[0].view) {
            responseMenu.push(menu);
          }
        }
        else {
          if (menu.icon == 'home') {
            const menuRoot = responseMenu.filter(f => f.parentId == 0 && f.icon != 'home');

            if (menuRoot != null && menuRoot.length > 0) {
              const subMenu = responseMenu.filter(f => f.parentId == menuRoot[0].id);

              if (subMenu == null || subMenu.length == 0) {
                responseMenu.pop();
              }
            }
          }

          responseMenu.push(menu);
        }
      });

      if (responseMenu[responseMenu.length - 1].parentId == 0) {
        responseMenu.pop();
      }

      return responseMenu;
    }
    catch (error) {
      console.error(error);
      return [];
    }
  }

  public expandActiveSubMenu(menu: MenuModel[]) {
    const url = this._location.path();
    const routerLink = url;
    const activeMenuItem = menu.filter(item => item.routerLink === routerLink);

    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];

      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId: number) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);

    if (menuItem && subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      } else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: MenuModel[], menuId) {
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];

    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach(item => {
        if (item.id != menuId) {
          let menuItem = document.getElementById('menu-item-' + item.id);
          let subMenu = document.getElementById('sub-menu-' + item.id);

          if (menuItem && subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      });
    }
  }

  public closeAllSubMenus() {
    const menu = document.getElementById('vertical-menu');
    
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  public getHideMenu() {
    const url = this._location.path();
    let hide = false

    if (url.includes("bidding")) {
      hide = true;
    }

    return hide;
  }

  public toggleSidenav() {
    this.toggleSidenavSubject.next();
  }

  public onToggleSidenav() {
    return this.toggleSidenavSubject.asObservable();
  }
}
