import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { TokenResponseModel } from 'src/app/models/auth/token-response.model';
import { PermissionModel } from 'src/app/models/systemuser/permission.model';
import { environment } from 'src/environments/environment';
import { UserSessionService } from './user-session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _url = environment.api;

  constructor(
    private _http: HttpClient,
    private _userSessionService: UserSessionService,
    private _actRouter: ActivatedRoute,
    private _router: Router
  ) { }

  token(email: string, password: string): Observable<TokenResponseModel> {
    const request = {
      login: email,
      password: password
    };

    return this._http.post<TokenResponseModel>(`${this._url}/auth/token`, request);
  }

  logout(): void {
    const token = this._userSessionService.token;
    const returnApp = this._actRouter.snapshot.queryParams.returnApp || '';

    if (token) {
      this._userSessionService.inactiveSession(token).subscribe({
        next: () => {
          this._userSessionService.reset();
          this._router.navigate(['/login'], { queryParams: { returnApp } });
        },

        error: () => {
          this._userSessionService.reset();
          this._router.navigate(['/login'], { queryParams: { returnApp } });
        }
      });
    }
    else {
      this._userSessionService.reset();
      this._router.navigate(['/login'], { queryParams: { returnApp } });
    }
  }

  getPermissionsByUser(userId: number): Observable<PermissionModel[]> {
    return this._http.get<PermissionModel[]>(`${this._url}/permission/getByUser/${userId}`);
  }

  sendNewPassword(data: any): Observable<any> {
    return this._http.post<any>(`${this._url}/Auth/v1/sendNewPassword`, data);
  }

  changePassword(data: any): Observable<any> {
    return this._http.post<any>(`${this._url}/Auth/v1/changePassword`, data);
  }
}
