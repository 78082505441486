import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnChanges {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() allItems: any = [];
  @Input() items: any = [];
  @Input() isUpdate: boolean;

  @Output() selectedItems = new EventEmitter<any>();

  @ViewChild('autocomplete') autocomplete: any;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  itemCtrl = new FormControl();
  filteredItems: Observable<any>;

  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;

  constructor() { }

  ngOnChanges(): void {
    this.filteredItems = this.itemCtrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filter(item) : this.allItems)),
    );
    if (!this.allItems)
      this.allItems = [];
  }

  remove(item: any): void {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      if (this.isUpdate && !!this.items[index].data.id) {
        this.items[index].deleted = !this.items[index].deleted;
        this.items[index].added = !this.items[index].deleted;
      } else {
        this.items.splice(index, 1);
        this.allItems.push(item);
      }
    }
    this.selectedItems.emit({ companyId: item.companyId, data: this.items });
  }

  changeSelectedItems(): void {
    const items = this.items.map(item => ({...item, added: true, deleted: false}));
    this.selectedItems.emit({ companyId: items.length ? items[0].companyId : undefined, data: this.items });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.allItems.splice(this.allItems.indexOf(event.option.value), 1);
    this.items.push(event.option.value);
    this.itemInput.nativeElement.value = '';
    this.itemCtrl.setValue(null);
    this.changeSelectedItems();
  }

  private _filter(value: any) {
    if (!value)
      return this.allItems;

    const filterValue = value instanceof Object ? value.label.toLowerCase() : value.toLowerCase();
    return this.allItems.filter(item => item.label.toLowerCase().includes(filterValue));
  }
}
