import {Component, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import { UserSessionService } from 'src/app/services/auth/user-session.service';
import { ApplicationsService } from 'src/app/services/systemuser/applications.service';
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {MatButton} from "@angular/material/button";
import {Subject} from "rxjs";
import {TemplatePortal} from "@angular/cdk/portal";


export interface IApplication {
  id: number;
  label: string;
  icon: string;
  link: string;
  description?: string;
  background?: string;
}

interface onDestroy {
}

@Component({
  selector: 'app-project-menu',
  templateUrl: './project-menu.component.html',
  styleUrls: ['./project-menu.component.scss']
})
export class ProjectMenuComponent implements OnInit, onDestroy {

  active: boolean = false;
  backgroundsApps = ['#283593', '#004e89', '#1a659e', '#209199', '#dc994a', '#362408'];

  // New

  @ViewChild('shortcutsOrigin') private _shortcutsOrigin: MatButton;
  @ViewChild('shortcutsPanel') private _shortcutsPanel: TemplateRef<any>;

  mode = 'view';
  shortcuts: IApplication[] = [];
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _userSessionService: UserSessionService,
    private _applicationsService: ApplicationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) { }

  async ngOnInit() {
    await this.getApplications();
  }

  ngOnDestroy(): void {
    if ( this._overlayRef )
    {
      this._overlayRef.dispose();
    }
  }

  goToApplication(app: IApplication) {
    const token = this._userSessionService.token;
    const url = app.label === 'SSO' ? app.link : (app.link + '/authorize?accessToken=' + token);
    window.open(url, '_blank');
  }

  async getApplications() {
    const companyId = this._userSessionService.selectedCompanyId;
    if (companyId) {
      this._applicationsService.getByCompany(companyId).subscribe({
        next: (apps) => {
          this.shortcuts = apps.map((el, i) => ({
            id: el.id,
            label: el.name,
            icon: el.icon,
            link: el.url,
            description: el.description,
            background: this.backgroundsApps[i]
          }));
        }
      });
    }
  }


//   NEW

  openPanel(): void
  {
    // Return if the shortcuts panel or its origin is not defined
    if ( !this._shortcutsPanel || !this._shortcutsOrigin )
    {
      return;
    }

    // Make sure to start in 'view' mode
    this.mode = 'view';

    // Create the overlay if it doesn't exist
    if ( !this._overlayRef )
    {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._shortcutsPanel, this._viewContainerRef));
  }

  /**
   * Close the shortcuts panel
   */
  closePanel(): void
  {
    this._overlayRef.detach();
  }

  trackByFn(index: number, item: any): any
  {
    return item.id || index;
  }

  private _createOverlay(): void
  {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop     : true,
      backdropClass   : 'fuse-backdrop-on-mobile',
      scrollStrategy  : this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position()
        .flexibleConnectedTo(this._shortcutsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX : 'start',
            originY : 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX : 'start',
            originY : 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX : 'end',
            originY : 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: 15,
            offsetX: 70
          },
          {
            originX : 'end',
            originY : 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          }
        ])
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }
}
