import { Directive, HostListener, Input,  ElementRef } from '@angular/core';

import {
  NG_VALUE_ACCESSOR, ControlValueAccessor
} from '@angular/forms';

@Directive({
  selector: '[MaskHour]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MaskHourDirective,
    multi: true
  }]
})


export class MaskHourDirective implements ControlValueAccessor {

  readonly myMask = '99:99';

   constructor(private el: ElementRef) { }
  onTouched: any;
  onChange: any;
  @Input('MaskHour') ehMask: string;
  writeValue(value: any): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('keyup', ['$event']) 
  onKeyup(event: any) {

    let valor = event.target.value.replace(/\D/g, '');
    if (valor.length === 0) {
      this.onChange(valor);
       event.preventDefault();
    }
    let pad = this.myMask.replace(/\D/g, '').replace(/9/g, '_');

    let valorMask = valor + pad.substring(0, pad.length - valor.length);

    // retorna caso pressionado backspace
    if (event.keyCode === 8 || valor.length==11) {
       this.onChange(valor);
       event.preventDefault();
      return;
    }

    let valorMaskPos = 0;
    valor = '';
    for (let i = 0; i < this.myMask.length; i++) {
      if (isNaN( parseInt(this.myMask.charAt(i) ) ) ) {
        valor += this.myMask.charAt(i);
      } else {
        valor += valorMask[valorMaskPos++];
      }
    }

    if (valor.indexOf('_') > -1) {
      valor = valor.substr(0, valor.indexOf('_'));
    }

    event.target.value = valor;
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    this.onChange(event.target.value.replace(/\D/g, ''));
  }

}