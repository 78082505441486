export class AppSettingsModel {
    name: string;
    loadingSpinner: boolean;
    fixedHeader: boolean;
    fixedSidenav: boolean;
    fixedSidenavUserContent: boolean;
    fixedFooter: boolean;
    sidenavIsOpened: boolean;
    sidenavIsPinned: boolean;
    menu: string;
    menuType: string;
    theme: string;
    rtl: boolean;

    constructor() {
        this.name = 'SSO Specialty';
        this.loadingSpinner = true;
        this.fixedHeader = true;
        this.fixedSidenav = true;
        this.fixedSidenavUserContent = false;
        this.fixedFooter = true;
        this.sidenavIsOpened = true;
        this.sidenavIsPinned = true;
        this.theme = 'indigo-light';
        this.menu = 'vertical';
        this.menuType = 'default';
        this.rtl = false;
    }
}