<div class="posiciona" *ngIf="this.isHomolog">
  <p style="padding-top: 0.3%;overflow: hidden;">
    Ambiente de Homologação!
  </p>
</div>

<div id="app" class="app indigo-light fixed-header fixed-sidenav fixed-footer" [dir]="(this.settings.rtl) ? 'rtl' : 'ltr'" [class]="this.settings.theme" [class.fixed-header]="this.settings.fixedHeader"
  [class.fixed-sidenav]="this.settings.fixedSidenav" [class.fixed-footer]="this.settings.fixedFooter"
  [class.horizontal-menu]="this.settings.menu == 'horizontal'" [class.compact]="this.settings.menuType == 'compact'"
  [class.mini]="this.settings.menuType == 'mini'">

  <router-outlet></router-outlet>

  <div id="app-spinner" [class.hide]="!this.settings.loadingSpinner">
    <img src="assets/images/logo-load.png" class="animated-logo">
    <h4>
      Aguarde um momento, por favor...
    </h4>
  </div>

</div>

<core-load-modal *ngIf="this.waitAMoment" message="{{this.messageWaitAMoment}}"></core-load-modal>
