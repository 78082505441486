<mat-form-field class="example-chip-list" appearance="outline">
  <mat-label>{{label}}</mat-label>
  <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip
      *ngFor="let item of items"
      (removed)="remove(item)"
      [class.itemremoved]="item.deleted"
      >
      {{item.label}}
      <button matChipRemove [class.color-white]="item.deleted"
        [matTooltip]="item.deleted ? 'Cancelar Remoção' : 'Remover vínculo'">
        <mat-icon>{{item.deleted ? 'cancel' : 'delete'}}</mat-icon>
      </button>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      #itemInput
      [formControl]="itemCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" #autocomplete (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      {{item.label}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
