import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppSettingsModel } from './models/common/app-settings.model';
import { BehaviorService } from './services/common/behavior.service';
import { SettingsService } from './services/common/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public settings: AppSettingsModel = new AppSettingsModel();
  public waitAMoment: boolean;
  public messageWaitAMoment: string;
  public isHomolog = environment.homolog;

  constructor(
    private behaviorService: BehaviorService,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.behaviorService.messagewaitAMoment.subscribe(value => {
      this.messageWaitAMoment = value;
    });

    this.behaviorService.waitAMoment.subscribe(value => {
      this.waitAMoment = value;

      if (!value) {
        this.messageWaitAMoment = '';
      }
    });

    this.settingsService.onChangeSettings().subscribe({
      next: (settings) => {
        this.settings = settings;
      }
    });
  }
}
