<div *ngFor="let menu of parentMenu" class="menu-item">
    <a *ngIf="menu.routerLink && !menu.hasSubMenu" mat-button fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
        [routerLink]="[menu.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}" [matTooltip]="menu.title"
        matTooltipPosition="after" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'" [id]="'menu-item-'+menu.id">
        <mat-icon class="menu-icon" *ngIf="menu.icon">{{menu.icon}}</mat-icon>
        <span class="menu-title">{{menu.title}}</span>
    </a>
    <a *ngIf="menu.href && !menu.hasSubMenu" mat-button fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
        [attr.href]="menu.href || ''" [attr.target]="menu.target || ''" [matTooltip]="menu.title" matTooltipPosition="after"
        [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'" (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <mat-icon class="menu-icon">{{menu.icon}}</mat-icon>
        <span class="menu-title">{{menu.title}}</span>
    </a>
    <a *ngIf="menu.hasSubMenu" mat-button fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
        [matTooltip]="menu.title" matTooltipPosition="after" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'" (click)="onClick(menu.id)"
        [id]="'menu-item-'+menu.id">
        <mat-icon class="menu-icon">{{menu.icon}}</mat-icon>
        <span class="menu-title">{{menu.title}}</span>
        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
    </a>

    <div *ngIf="menu.hasSubMenu" class="sub-menu" [id]="'sub-menu-'+menu.id" (click)="logmenu($event)">
        <app-menu [menuItems]="menuItems" [menuParentId]="menu.id"></app-menu>
    </div>
</div>
