import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AppSettingsModel } from 'src/app/models/common/app-settings.model';
import { MenuModel } from 'src/app/models/common/menu.model';
import { MenuService } from 'src/app/services/common/menu.service';
import { SettingsService } from 'src/app/services/common/settings.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;

  public settings: AppSettingsModel = new AppSettingsModel();
  public parentMenu: MenuModel[];

  constructor(
    private menuService: MenuService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);

    this.settingsService.onChangeSettings().subscribe({
      next: (settings) => {
        this.settings = settings;
      }
    });
  }

  onClick(menuId) {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }
  
  logmenu($event) {
    if ($event.target.textContent === 'Licitação') {
      this.menuService.toggleSidenav();
    }
  }
}
